<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <div slot="title">
      <span style="font-weight: 700;font-size: 18px">标记</span>
    </div>
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"

      label-width="120px"
    >
      <el-form-item
        label="状态"
      >
        <el-select
          v-model="form.status"
          placeholder="请选择状态"
          prop="status"
          :rules="[ { required: true, message: '状态不能为空', trigger: 'change' }]"
        >
          <el-option
            v-for="(item,index) in statusOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { UpdatePlanStatus } from '@/api/project/project'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: {
        status: '',
        id: 0,
      },
      planId: Number(this.$route.query.id),
      statusOptions: [
        { label: '待处理', value: 1 },
        { label: '处理中', value: 2 },
        { label: '处理完成', value: 3 },
      ],
    }
  },
  methods: {
    dialogOpen() {

    },
    dialogClose() {
      this.form = {
        status: '',
        id: 0,
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          UpdatePlanStatus(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              this.loading = false
              error(resData.msg)
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>
