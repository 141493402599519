<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <div slot="title">
      <span style="font-weight: 700;font-size: 18px">{{ form.id ? '编辑计划' : '新增计划' }}</span>
    </div>
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"

      label-width="120px"
    >
      <el-form-item
        label="计划名称"
        prop="planName"
        :rules="[ { required: true, message: '计划名称不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.planName"
          maxlength="30"
        />
      </el-form-item>
      <el-form-item
        label="计划类型"
        prop="planType"
        :rules="[ { required: true, message: '计划类型不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.planType"
          placeholder="请选择业务系统"
          @change="changeRelationOptions"
        >
          <el-option
            v-for="(item,index) in planTypeOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.planType !== 4"
        class="form-item-flex"
        label="关联类型"
        prop="relationType"
        :rules="[ { required: true, message: '关联类型不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.relationType"
          class="mr10"
          placeholder="请选择关联类型"
          @change="changeRelationOptions"
        >
          <el-option
            v-for="(item,index) in relationTypeOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-select
          v-if="form.relationType === 1"
          v-model="form.relationId"
          placeholder="请选择关联数据"
          @change="selectRelation"
        >
          <el-option
            v-for="(item,index) in relationOptions"
            :key="index"
            :label="item.title"
            :value="item.Id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.relationType === 2 || form.planType === 4"
        label="阶段负责人"
        prop="responsiblePersonId"
        :rules="[ { required: true, message: '项目发起人不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.responsiblePersonId"
          class="mr10"
          placeholder="请选择人员"
        >
          <el-option
            v-for="(item,index) in users"
            :key="index"
            :label="item.actualName || item.nickName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.relationType === 1 && form.relationId !== ''"
        label="阶段负责人："
      >
        <span>{{ responsiblePerson }}</span>
      </el-form-item>
      <el-form-item
        v-if="form.relationType === 2 || form.planType === 4"
        label="计划时间"
        prop="time"
        :rules="[ { required: true, message: '完成时间不能为空', trigger: 'change' }]"
      >
        <el-date-picker
          v-model="form.time"
          format="yyyy-MM-dd"
          placement="bottom-start"
          value-format="yyyy-MM-dd"
          type="daterange"
          placeholder="选择日期"
        />
      </el-form-item>
      <el-form-item
        v-if="form.relationType === 1 && form.relationId !== '' "
        label="计划时间："
      >
        <span>{{ form.planBeginTime }} 至 {{ form.planEndTime }}</span>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { queryUserOptions } from '@/api/system/system'
import { saveOrUpdatePlan, GetAssociationOptions } from '@/api/project/project'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      responsiblePerson: '',
      form: {
        planBeginTime: '',
        planEndTime: '',
        planType: '',
        time: '',
        planName: '',
        projectStageId: '',
        relationId: '',
        relationType: '',
        projectManageId: Number(this.$route.query.id),
        responsiblePersonId: '',
        id: 0,
      },
      planId: Number(this.$route.query.id),
      users: [],
      relationOptions: [

      ],
      planTypeOptions: [
        { label: 'ITIX信息', value: 1 },
        // { label: 'PIA评估', value: 2 },
        // { label: '303645', value: 3 },
        { label: '其他', value: 4 },
      ],
      relationTypeOptions: [
        { label: '关联数据', value: 1 },
        { label: '新增数据', value: 2 },
      ],
    }
  },
  methods: {
    selectRelation() {
      this.relationOptions.forEach(r => {
        if (r.Id === this.form.relationId) {
          this.responsiblePerson = r.responsiblePerson
          this.form.responsiblePersonId = r.responsiblePersonId
          this.form.planBeginTime = r.planBeginTime
          this.form.planEndTime = r.planEndTime
        }
      })
    },
    changeRelationOptions() {
      this.responsiblePerson = ''
      this.form.planBeginTime = ''
      this.form.planBeginTime = ''
      this.form.responsiblePersonId = ''
      this.form.relationId = ''
      this.relationOptions = []
      if (this.form.relationType === 1 && this.form.planType !== 4) {
        this.getAssociationOptions()
      }
    },
    getAssociationOptions() {
      GetAssociationOptions(this.form.planType).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.relationOptions = resData.data
        }
      })
    },
    queryUserList() {
      queryUserOptions(0)
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.users = resData.data
          }
        })
    },
    dialogOpen() {
      // this.getAssociationOptions()
      this.queryUserList()
      if (this.form.relationType === 1 && this.form.planType !== 4) {
        GetAssociationOptions(this.form.planType).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.relationOptions = resData.data
            this.selectRelation()
          }
        })
      }
    },
    dialogClose() {
      this.form = {
        planBeginTime: '',
        planEndTime: '',
        planType: '',
        time: '',
        planName: '',
        projectStageId: '',
        relationId: '',
        relationType: '',
        projectManageId: Number(this.$route.query.id),
        responsiblePersonId: '',
        id: 0,
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          if (this.form.relationType === 2 || this.form.planType === 4) {
            this.form.planBeginTime = this.form.time[0]
            this.form.planEndTime = this.form.time[1]
          }
          if (this.form.planType === 4) {
            this.form.relationId = 0
            this.form.relationType = 0
          }
          this.form.relationId = this.form.relationId ? this.form.relationId : 0
          saveOrUpdatePlan(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
  },
}
</script>
