<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <div slot="title">
      <span style="font-weight: 700;font-size: 18px">{{ form.id ? '编辑' : '新增' }}</span>
    </div>
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"

      label-width="120px"
    >
      <el-form-item
        label="阶段名称"
        prop="stageName"
        :rules="[ { required: true, message: '阶段名称不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.stageName"
          maxlength="30"
        />
      </el-form-item>
      <el-form-item
        label="前阶段"
      >
        <el-select
          v-model="form.prevStageId"
          placeholder="请选择前阶段"
        >
          <el-option
            v-for="(item,index) in projectStages"
            :key="index"
            :label="item.stageName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="阶段负责人"
        prop="responsiblePersonId"
        :rules="[ { required: true, message: '项目发起人不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.responsiblePersonId"
          class="mr10"
          placeholder="请选择人员"
        >
          <el-option
            v-for="(item,index) in users"
            :key="index"
            :label="item.actualName || item.nickName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="计划时间"
        prop="time"
        :rules="[ { required: true, message: '完成时间不能为空', trigger: 'change' }]"
      >
        <el-date-picker
          v-model="form.time"
          format="yyyy-MM-dd"
          placement="bottom-start"
          value-format="yyyy-MM-dd"
          type="daterange"
          placeholder="选择日期"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { queryUserOptions } from '@/api/system/system'
import { saveOrUpdateProjectStage, GetStageOptions } from '@/api/project/project'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: {
        planBeginTime: '',
        planEndTime: '',
        time: '',
        stageName: '',
        projectManageId: Number(this.$route.query.id),
        prevStageId: '',
        responsiblePersonId: '',
        id: 0,
      },
      planId: Number(this.$route.query.id),
      projectStages: [],
      users: [],
    }
  },
  methods: {
    getSteps() {
      const filterId = this.form.id ? this.form.id : 0
      GetStageOptions(this.planId, filterId).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.projectStages = resData.data
          if (this.projectStages.length > 0 && !this.form.id) {
            this.form.prevStageId = this.projectStages[this.projectStages.length - 1].id
          }
        }
      })
    },
    queryUserList() {
      queryUserOptions(0)
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.users = resData.data
          }
        })
    },
    dialogOpen() {
      this.queryUserList()
      this.getSteps()
    },
    dialogClose() {
      this.form = {
        planBeginTime: '',
        planEndTime: '',
        time: '',
        stageName: '',
        projectManageId: Number(this.$route.query.id),
        prevStageId: '',
        responsiblePersonId: '',
        id: 0,
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.form.planBeginTime = this.form.time[0]
          this.form.planEndTime = this.form.time[1]
          this.form.prevStageId = this.form.prevStageId ? this.form.prevStageId : 0
          saveOrUpdateProjectStage(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
  },
}
</script>
