<template>
  <div class="plan-save">
    <b-card title="项目信息">
      <b-row>
        <b-col md="4">
          <div class="desc-wrap">
            <div class="desc-label">
              项目名称：
            </div>
            <div class="desc-text">
              {{ planInfo.projectName }}
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="desc-wrap">
            <div class="desc-label">
              项目发起人：
            </div>
            <div class="desc-text">
              {{ planInfo.sponsorName }}
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="desc-wrap">
            <div class="desc-label">
              项目负责人：
            </div>
            <div class="desc-text">
              <span>{{ planInfo.responsiblePersonName }}</span>
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="desc-wrap">
            <div class="desc-label">
              项目计划时间：
            </div>
            <div class="desc-text">
              <span>{{ planInfo.planBeginTime }} ~ {{ planInfo.planEndTime }}</span>
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="desc-wrap">
            <div class="desc-label">
              项目说明：
            </div>
            <div class="desc-text">
              {{ planInfo.remark }}
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="项目阶段">
      <div
        v-if="stageList.length > 0"
        class="flex-start lengend-wrap"
      >
        <div class="lengend">
          待处理
        </div>
        <div class="lengend">
          处理中
        </div>
        <div class="lengend">
          处理完成
        </div>
      </div>
      <el-link
        v-if="stageList.length === 0"
        type="primary"
        style="position: absolute;top: 18px;left: 100px"
        @click="imgDialogVisible = true"
      >
        流程指引
      </el-link>
      <div
        class="flex-end"
        style="position: absolute;right: 50px;top: 10px"
      >
        <el-button @click="$router.push('/project')">
          返 回
        </el-button>
        <el-button
          type="primary"
          @click="stepDialogShow(1)"
        >
          添 加
        </el-button>
      </div>
      <ul
        v-if="stageList.length > 0"
        v-loading="loading"
        class="steps"
        :style="{height: pageH - 300 + 'px'}"
      >
        <li
          v-for="(item,index) in stageList"
          :key="index"
          :class="statusColorObj[item.finishStatus]"
        >
          <div class="step-line" />
          <div class="step-content">
            <!--            <i class="iconfont icon-chulizhong"></i>-->
            <div
              class="step-num"
              :class="index === stageList.length -1 ? 'last-stage' : ''"
            >
              {{ index + 1 }}
              <!--              <span class="status-str">{{ finishStatusObj[item.finishStatus] }}</span>-->
              <!--              <span class="status-str">-->
              <!--                <i class="iconfont icon-chulizhong"></i>-->
              <!--              </span>-->
              <div class="plan-btn-wrap">
                <el-button
                  plain
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  @click="stepDialogShow(2,item)"
                />
                <el-button
                  plain
                  type="warning"
                  icon="el-icon-plus"
                  circle
                  @click="planSaveDialogShow(1,item)"
                />
                <el-button
                  plain
                  type="success"
                  icon="el-icon-s-claim"
                  circle
                  @click="updateStatusDialogShow(1,item)"
                />
                <el-button
                  plain
                  icon="el-icon-delete"
                  circle
                  @click="delStage(item.id)"
                />
              </div>
            </div>
            <div class="sub_title">
              {{ item.stageName }}
            </div>
            <div class="step-sub">
              <el-timeline>
                <el-timeline-item
                  v-for="(plan,k) in item.planList"
                  :key="k"
                  placement="top"
                >
                  <div :class="planStatusCls(plan)">
                    <p class="sub-content-item">
                      {{ planStatusStr(plan) }}
                      <i
                        v-if="plan.auditResult === 1"
                        class="el-icon-warning cursor-pointer"
                        @click="rejectDialogShow(plan)"
                      />
                    </p>
                    <el-card style="width:100%">
                      <h4
                        :style="(plan.planType === 1 && plan.relationType === 1) ? {cursor: 'pointer'} : {}"
                        @click="toBusiness(plan)"
                      >
                        {{ plan.planName }}
                      </h4>
                      <div class="sub-btn-wrap">
                        <el-button
                          plain
                          type="primary"
                          icon="el-icon-edit"
                          circle
                          @click="planSaveDialogShow(2,item,plan)"
                        />
                        <!--                        <el-button-->
                        <!--                          v-if="plan.finishStatus === 3"-->
                        <!--                          plain-->
                        <!--                          type="warning"-->
                        <!--                          icon="el-icon-s-check"-->
                        <!--                          circle-->
                        <!--                          @click="planAuditShow(plan)"-->
                        <!--                        />-->
                        <el-button
                          v-if="plan.planType === 4"
                          plain
                          type="success"
                          icon="el-icon-s-claim"
                          circle
                          @click="updateStatusDialogShow(2,plan)"
                        />
                        <el-button
                          plain
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          @click="planDel(plan.id)"
                        />
                      </div>
                    </el-card>
                    <!--                    <el-popover-->
                    <!--                      v-if="plan.auditResult ===1 && plan.auditReason"-->
                    <!--                      placement="top"-->
                    <!--                      trigger="click"-->
                    <!--                    >-->
                    <!--                      {{ plan.auditReason }}-->
                    <!--                      <i-->
                    <!--                        slot="reference"-->
                    <!--                        class="iconfont icon-bohui"-->
                    <!--                      />-->
                    <!--                    </el-popover>-->
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
          </div>
        </li>
      </ul>
      <el-empty
        v-else
        description="暂无数据"
      />
      <StepSave
        ref="stepSave"
        @handleFilter="getDetail"
      />
      <UpdateStageStatis
        ref="updateStageStatis"
        @handleFilter="getDetail"
      />
      <PlanSave
        ref="planSave"
        @handleFilter="getDetail"
      />
      <PlanAudit
        ref="planAudit"
        @handleFilter="getDetail"
      />
      <UpdatePlanStatus
        ref="updatePlanStatus"
        @handleFilter="getDetail"
      />
    </b-card>
    <el-dialog
      title="流程指引"
      :visible.sync="imgDialogVisible"
      width="800px"
    >
      <img
        class="img-fluid"
        src="../../../assets/images/plan.png"
        alt=""
      >
    </el-dialog>
    <el-dialog
      title="驳回信息"
      :visible.sync="rejectDialogVisible"
      width="600px"
    >
      <el-descriptions :column="1">
        <el-descriptions-item label="审核人">
          {{ rejectInfo.auditName }}
        </el-descriptions-item>
        <el-descriptions-item label="驳回时间">
          {{ rejectInfo.auditTime }}
        </el-descriptions-item>
        <el-descriptions-item label="驳回理由">
          {{ rejectInfo.auditReason }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>

</template>

<script>
import { PorjectManageDetail, StageDel, PlanDel } from '@/api/project/project'
import StepSave from '@/views/dataIntelligent/project/StepSave.vue'
import PlanSave from '@/views/dataIntelligent/project/PlanSave.vue'
import PlanAudit from '@/views/dataIntelligent/project/PlanAudit.vue'
import UpdatePlanStatus from '@/views/dataIntelligent/project/UpdatePlanStatus.vue'
import UpdateStageStatis from '@/views/dataIntelligent/project/UpdateStageStatus.vue'
import { error, success } from '@/@core/utils/utils'

export default {
  components: {
    StepSave,
    UpdateStageStatis,
    PlanSave,
    PlanAudit,
    UpdatePlanStatus,
  },
  data() {
    return {
      imgDialogVisible: false,
      rejectDialogVisible: false,
      rejectInfo: {
        auditTime: '',
        auditReason: '',
        auditName: '',
      },
      loading: false,
      planInfo: {},
      stageList: [],
      finishStatusObj: {
        0: '待处理',
        1: '待处理',
        2: '处理中',
        3: '处理完成',
      },
      statusColorObj: {
        0: 'step_edd',
        1: ' step_edd',
        2: 'step_active',
        3: 'step_pass',
      },
      planStatusClsObj: {
        0: '',
        1: 'plan-wating',
        2: 'plan-process',
        3: 'plan-pass',
      },
      id: Number(this.$route.query.id),
      init: true,
    }
  },
  created() {
    this.getDetail()
  },
  mounted() {
    this.navActiveInit(1, '项目列表', '项目管理')
  },
  destroyed() {
    this.navActiveInit(2, '项目列表', '项目管理', '/project')
  },
  methods: {
    toBusiness(plan) {
      if (plan.relationType === 1 && plan.planType !== 4) {
        this.$router.push({ path: '/businessFill', query: { id: plan.relationId } })
      }
    },
    rejectDialogShow(plan) {
      this.rejectInfo = plan
      this.rejectDialogVisible = true
    },
    planStatusCls(plan) {
      if (plan.auditResult) {
        return plan.auditResult === 1 ? 'plan-reject' : 'plan-pass'
      }
      return this.planStatusClsObj[plan.finishStatus]
      return ''
    },
    planStatusStr(plan) {
      if (plan.auditResult) {
        return plan.auditResult === 1 ? '处理完成(审核驳回)' : '处理完成(审核通过)'
      }
      return this.finishStatusObj[plan.finishStatus]
      return ''
    },
    delStage(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          StageDel({ id })
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.getDetail()
              } else {
                error(resData.msg)
              }
            })
        })
    },
    planDel(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          PlanDel({ id })
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.getDetail()
              } else {
                error(resData.msg)
              }
            })
        })
    },
    planAuditShow(plan) {
      this.$refs.planAudit.dialogVisible = true
      this.$refs.planAudit.form.id = plan.id
      this.$refs.planAudit.form.auditResult = plan.auditResult ? plan.auditResult : ''
      this.$refs.planAudit.form.auditReason = plan.auditReason
    },
    planSaveDialogShow(type, item, childItem) {
      this.$refs.planSave.dialogVisible = true
      this.$refs.planSave.form.projectStageId = item.id
      if (type === 2) {
        for (const i in this.$refs.planSave.form) {
          this.$refs.planSave.form[i] = childItem[i]
        }
        this.$refs.planSave.form.time = (childItem.planBeginTime && childItem.planEndTime) ? [childItem.planBeginTime, childItem.planEndTime] : ''
        this.$refs.planSave.form.relationType = this.$refs.planSave.form.relationType ? this.$refs.planSave.form.relationType : ''
      }
    },
    stepDialogShow(type, item) {
      this.$refs.stepSave.dialogVisible = true
      if (type === 2) {
        for (const i in this.$refs.stepSave.form) {
          this.$refs.stepSave.form[i] = item[i]
        }
        this.$refs.stepSave.form.time = (item.planBeginTime && item.planEndTime) ? [item.planBeginTime, item.planEndTime] : ''
        this.$refs.stepSave.form.prevStageId = this.$refs.stepSave.form.prevStageId ? this.$refs.stepSave.form.prevStageId : ''
      }
    },
    updateStatusDialogShow(type, item) {
      if (type === 1) {
        this.$refs.updateStageStatis.dialogVisible = true
        this.$refs.updateStageStatis.form.id = item.id
        this.$refs.updateStageStatis.form.status = item.finishStatus
      } else {
        this.$refs.updatePlanStatus.dialogVisible = true
        this.$refs.updatePlanStatus.form.id = item.id
        this.$refs.updatePlanStatus.form.status = item.finishStatus
      }
    },
    getDetail() {
      this.loading = true
      PorjectManageDetail(this.id).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          this.planInfo = resData.data
          this.stageList = resData.data.stageList
          if (this.init && this.stageList.length === 0) {
            this.imgDialogVisible = true
          }
          this.init = false
        }
      }).catch(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss">
.lengend-wrap{
  position: absolute;
  top: 20px;
  left: 95px;
}
.lengend{
  position: relative;
  margin-right: 10px;
  text-indent: 20px;
}
.lengend:before{
  position: absolute;
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.lengend:nth-child(1):before{
  background: #b9b9b9;
  color: #b9b9b9;
}
.lengend:nth-child(2):before{
  background: #f3862d;
  color: #f3862d;
}
.lengend:nth-child(3):before{
  background: #1dce05;
  color: #1dce05;
}
.status-str{
  position: absolute;
  top: 5px;
  //z-index: 99999;
  left: 43px;
  color: #6e6b7b;
  font-size: 12px;
  display: inline-block;
  //width: 120px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid;
  padding: 0 10px;
  transform: rotate(-45deg) scale(.8);
}
.plan-save{
  .el-timeline-item__content{
    width: 80%;
  }
  .steps {
    list-style: none;
    display: flex;
    overflow-x: scroll;
  }
  .steps li {
    flex: 1;
    position: relative;
    min-width: 240px;
  }
  .step-num {
    display: inline-block;
    height: 80px;
    width: 80px;
    color: #fff;
    background-color: #b9b9b9;
    line-height: 80px;
    border-radius: 50%;
    text-align: center;
  }
  .step_active .step-num {
    background-color: #f3862d;
  }
  .step_pass .step-num {
    background-color: #1dce05;
  }
  .step-num:after {
    content: "";
    width: 90%;
    height: 5px;
    background-color: #e0e0e0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 40px;
    margin-left: 36px;
  }
   .last-stage.step-num:after {
    display: none !important;
  }
  .step_pass .step-num:after {
    background-color: #2ad613;
  }
  .step-sub {
    //width: 90%;
    position: absolute;
    left: 28px;
  }
  .sub_title{
    //padding-left: 20px;
    width: 80px;
    text-align: center;
    word-break: keep-all;
    white-space: pre;
    padding-top: 5px;
    font-size: 14px;
  }
  .step-content{
    position: relative;
  }
  .plan-btn-wrap{
    position: absolute;
    top: 25px;
    left: 75px;
    text-align: center;
    display: none;
  }
  .step-num:hover{
    .plan-btn-wrap{
      display: block;
    }
  }
  .el-button--small.is-circle {
    padding: 4px !important;
  }
  .sub-content-item{
    word-break: break-all;
    margin-bottom: 5px !important;
  }
  .el-card__body{
    position: relative;
  }
  .step-sub .sub-btn-wrap{
    right: 14px;
    top: 12px;
    position: absolute;
    text-align: center;
    display: none;
  }
  .el-card__body:hover{
    .sub-btn-wrap{
      display: block;
    }
  }
   .el-timeline-item__timestamp.is-top {
    margin-bottom: 0!important;
     padding-top: 0!important;
  }
  .plan-pass{
    .el-card__body{
      border: 1px solid #1dce05;
      border-radius: 4px;
    }
    .sub-content-item{
      color: #1dce05;
    }
    .sub-content-item,h4{
      color: #1dce05;
    }
  }
  .plan-process{
    .el-card__body{
      border: 1px solid #f3862d;
      border-radius: 4px;
    }
    .sub-content-item{
      color: #f3862d;
    }
    .sub-content-item,h4{
      color: #f3862d;
    }
  }
  .plan-reject{
    .el-card__body{
      border: 1px solid rgb(234,84,85);
      border-radius: 4px;
    }
    .sub-content-item,h4{
      color: rgb(234,84,85);
    }
  }
  .plan-wating{
    .el-card__body{
      border: 1px solid rgb(168,170,174);
      border-radius: 4px;
    }
    .sub-content-item,h4{
      color: rgb(168,170,174);
    }
  }
.icon-bohui{
  font-size: 51px;
  z-index: 222;
  right: 0;
  top: -30px;
}
}
</style>
